import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18next/i18n';

import './styles/index.scss';
import App from './App';
import { store } from './store';

const loadingMarkup = <div className="h-screen bg-zinc-900"></div>;

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <Provider store={store}>
            <App />
          </Provider>
        </BrowserRouter>
      </I18nextProvider>
    </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
);
