import React from 'react';
import About from '../../components/about/About';
import Intro from '../../components/intro/Intro';
import Products from '../../components/products/Products';
import Contact from './../../components/contact/Contact';

const Home = () => {
  return (
    <div className="bg-zinc-900 w-full h-full p-4 overflow-x-hidden">
      <Intro />
      <About />
      <Products />
      <Contact />
    </div>
  );
};

export default Home;
