import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logor from '../../assets/images/logor.png';
import cookie from 'js-cookie';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

import { languages } from '../../i18next/languages';

const Intro = () => {
  const { t } = useTranslation();
  const [direction, setDirection] = useState('left');
  const arrow =
    direction === 'left' ? (
      <BsArrowRight size={50} />
    ) : (
      <BsArrowLeft size={50} />
    );

  useEffect(() => {
    const currentLanguageCode = cookie.get('i18next') || 'en';
    const currentLanguage = languages.find(
      (l) => l.code === currentLanguageCode
    );

    if (currentLanguage.dir === 'rtl') setDirection('right');
    else setDirection('left');
  }, [t]);

  function handleScroll() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <div className="flex flex-col items-center md:flex-row md:flex-wrap md:justify-between md:items-center mt-20  w-full  p-4 md:px-1 lg:px-10">
      <div className="w-full py-5 md:py-0 mb-10 text-center font-serif ltr:md:text-left rtl:md:text-right ltr:md:justify-start rtl:md:justify-end  capitalize md:w-7/12 lg:w-2/4 text-4xl md:text-5xl xl:text-6xl ltr:tracking-wider text-amber-100">
        <p className="leading-tight"> {t('heading_hero_1')}</p>
        <p className="leading-tight"> {t('heading_hero_2')}</p>
      </div>
      <div className="w-full logor mx-auto min-w-md max-w-md md:w-5/12 lg:w-2/4 ">
        <img className="w-full h-full" src={logor} alt="logo" />
      </div>
      <div className="mt-24 w-full flex justify-center md:justify-start text-2xl md:text-3xl md:-mt-72 ltr:md:ml-2 rtl:md:mr-2 md:w-2/4  text-amber-100">
        <Link onClick={handleScroll} to="/products">
          <button
            className="p-4 ease-in-out hover:scale-110 hover:text-amber-50 flex items-center border-solid border border-slate-500 rounded-3xl active:translate-y-1 z-10  duration-200 md:mt-32"
            type="button"
          >
            {t('explore')}
            <span className="text-5xl ltr:ml-2 rtl:mr-2 inline mt-2">
              {arrow}
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Intro;
