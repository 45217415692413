import React, { useEffect, useState } from 'react';
import Card from '../../components/card/Card';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Link, useLocation } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { useTranslation } from 'react-i18next';

const ProductsPage = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [forcePage, setForcePage] = useState(0);
  const [loading, setLoading] = useState(true);
  const products = useSelector((state) => state.product.products);

  const [pageNumber, setPageNumber] = useState(0);
  const productsPerPage = 12;
  const pagesVisited = pageNumber * productsPerPage;

  const displayProducts = products
    .slice(pagesVisited, pagesVisited + productsPerPage)
    .map((product) => {
      return (
        <Link
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          key={product.id}
          to={`/products/${product.id}`}
        >
          <Card title={product.title} img={product.images[0]}></Card>
        </Link>
      );
    });

  const pageCount = Math.ceil(products.length / productsPerPage);

  function changePage({ selected }) {
    setPageNumber(selected);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  // function forcePage({ selected }) {
  //   if (selected) return selected || 1;
  // }

  useEffect(() => {
    if (products.length !== 0) {
      setLoading(false);
    }
  }, [products.length]);

  useEffect(() => {
    if (state >= 0 && state <= 11) {
      setForcePage(0);
    } else if (state >= 12 && state <= 23) {
      setForcePage(1);
      setPageNumber(1);
    } else if (state >= 24 && state <= 35) {
      setForcePage(2);
      setPageNumber(2);
    } else if (state >= 36 && state <= 47) {
      setForcePage(3);
      setPageNumber(3);
    } else if (state >= 48 && state <= 59) {
      setForcePage(4);
      setPageNumber(4);
    } else if (state >= 60 && state <= 71) {
      setForcePage(5);
      setPageNumber(5);
    } else if (state >= 72 && state <= 83) {
      setForcePage(6);
      setPageNumber(6);
    } else if (state >= 84 && state <= 95) {
      setForcePage(7);
      setPageNumber(8);
    } else if (state >= 96 && state <= 107) {
      setForcePage(9);
      setPageNumber(9);
    }
  }, [state]);

  return (
    <div
      className={`bg-zinc-900 w-full ${
        products[0] ? 'h-full' : 'h-screen'
      } p-4 overflow-x-hidden`}
    >
      {loading ? (
        <div className="h-full flex items-center justify-center">
          <ClipLoader color="white" size={150} />
        </div>
      ) : (
        <div className="flex flex-col items-center w-full mt-20 px-4">
          <h1 className="text-5xl text-amber-100 capitalize mt-4">
            {t('products')}
          </h1>
          <div className="sm:grid gap-y-1 sm:gap-x-8 sm:gap-y-8 sm:grid-cols-1 lg:grid-cols-2 md:grid-cols-1 xl:grid-cols-3 md:justify-center py-10 w-full">
            {displayProducts}
          </div>
          <ReactPaginate
            previousLabel={t('previous')}
            nextLabel={t('next')}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName="pagination__container"
            previousLinkClassName="pagination__prev"
            nextLinkClassName="pagination__next"
            disabledClassName="pagination__disabled"
            activeClassName="pagination__active"
            forcePage={forcePage}
          />
        </div>
      )}
    </div>
  );
};

export default ProductsPage;
