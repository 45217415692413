import React from 'react';
import productImg from '../../assets/images/products.png';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();
  return (
    <div
      id="about"
      className="w-full sm:p-4 md:px-1 flex flex-col md:flex-row md:items-center md:mt-10 lg:mt-14"
    >
      <div className="product__img w-full flex justify-center max-w-lg mx-auto  mt-10 md:w-2/4 xl:w-2/5 lg:mt-30">
        <img className="w-full h-full" src={productImg} alt="products" />
      </div>
      <div className="text-xl md:text-2xl p-4 text-justify w-full md:w-2/4 lg:w-3/5 lg:flex lg:items-center text-amber-100 tracking-wide mt-10 leading-8">
        {t('about_section')}
      </div>
    </div>
  );
};

export default About;
