import React, { useState, useRef, useEffect } from 'react';
import { registerObserver } from '../../helper/insersectionObserver';
import ImageWithLoading from '../image-with-loading/ImageWithLoading';

const LazyImage = ({ src, alt, classname, placeholderClassname }) => {
  const [showImage, setShowImage] = useState(false);
  const placeHolderRef = useRef(null);

  useEffect(() => {
    const observer = registerObserver(placeHolderRef.current, setShowImage);
    return () => {
      observer.disconnect();
    };
  }, []);

  if (showImage) {
    return <ImageWithLoading src={src} alt={alt} classname={classname} />;
  }
  return <div ref={placeHolderRef} className={placeholderClassname} />;
};

export default LazyImage;
