export const languages = [
  {
    id: 1,
    code: 'en',
    name: 'English',
    country_code: 'gb',
    dir: 'ltr',
  },
  {
    id: 2,
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
];
