import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import logo from '../../assets/images/logo.png';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { BsGlobe } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import cookie from 'js-cookie';
import { languages } from '../../i18next/languages';

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const currentLanguageCode = cookie.get('i18next') || 'en';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  const [isOpen, setIsOpen] = useState(false);
  const [isGlobeOpen, setIsGlobeOpen] = useState(false);

  function handleScroll() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr';
  }, [currentLanguage.dir]);

  return (
    <div className="flex text-xl w-full text-amber-100 bg-zinc-900   md:grid md:grid-cols-3 fixed top-0 left-0 z-20 pt-3 ltr:pl-3 flex-col">
      <div className="flex justify-between mt-1 ">
        <img className="logo  ltr:ml-5 rtl:mr-5" src={logo} alt="logo" />
        <div className="px-4 py-0 md:hidden">
          {isOpen ? (
            <AiOutlineClose
              className=" cursor-pointer"
              onClick={() => setIsOpen(!isOpen)}
              size="2rem"
            />
          ) : (
            <AiOutlineMenu
              className=" cursor-pointer"
              onClick={() => setIsOpen(!isOpen)}
              size="2rem"
            />
          )}
        </div>
      </div>
      <div className={`md:block md:col-span-2  ${isOpen ? 'block' : 'hidden'}`}>
        <div className="flex flex-col md:flex-row items-center mx-auto md:mx-0  text-2xl mt-1 w-full ">
          <Link
            to="/"
            className=" hover:scale-110 pb-5 ltr:lg:pl-5 rtl:lg:pr-5 hover:text-amber-50 ease-in-out"
            onClick={() => {
              handleScroll();
              setIsOpen(false);
            }}
          >
            {t('home')}
          </Link>
          <HashLink
            to="/#about"
            smooth={true}
            className="ltr:md:px-10 rtl:md:px-10 pb-5 hover:scale-110 hover:text-amber-50 ease-in-out"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            {t('about')}
          </HashLink>
          <Link
            to="/products"
            className="ltr:md:pr-10 rtl:md:pl-10 rtl:min-w-md pb-5 hover:scale-110 hover:text-amber-50 ease-in-out"
            onClick={() => {
              handleScroll();
              setIsOpen(false);
            }}
          >
            {t('products')}
          </Link>
          <HashLink
            to="/#contact"
            smooth={true}
            className="cursor-pointer pb-5 hover:scale-110 hover:text-amber-50 ease-in-out"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            {t('contact')}
          </HashLink>
          <div className="pb-5 inline-block ltr:md:ml-auto ltr:md:mr-4 rtl:md:mr-auto relative md:pl-4">
            <BsGlobe
              onClick={() => setIsGlobeOpen(!isGlobeOpen)}
              className="cursor-pointer inline-block hover:scale-110 hover:text-amber-50 ease-in-out"
              size={35}
            />
            <div
              className={`px-4 absolute text-center rounded-lg top-11 ltr:right-0 py-1 rtl:left-0 ${
                isGlobeOpen ? 'block' : 'hidden'
              }`}
            >
              <ul>
                {languages.map((lang) => {
                  return (
                    <li
                      key={lang.id}
                      onClick={() => {
                        i18n.changeLanguage(lang.code);
                        setIsGlobeOpen(false);
                      }}
                      className={`bg-white first:rounded-t-lg last:rounded-b-lg px-9 py-1 text-zinc-800 cursor-pointer hover:bg-zinc-900 hover:text-white ${
                        lang.code === currentLanguageCode
                          ? 'text-zinc-900 font-medium bg-slate-100'
                          : ''
                      } `}
                    >
                      {lang.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
