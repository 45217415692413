import React, { useState, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const ImageWithLoading = ({ src, alt, classname }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      const image = new Image();
      image.src = src;
      image.onload = () => setIsLoaded(true);
    }

    return () => {
      isCancelled = true;
    };
  }, [src]);

  return isLoaded ? (
    <img src={src} alt={alt} className={classname} />
  ) : (
    <div className="flex justify-center items-center h-full">
      <ClipLoader color="white" size={150} />
    </div>
  );
};

export default ImageWithLoading;
