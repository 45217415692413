import React, { useState, useEffect } from 'react';
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from 'react-icons/bs';
import cookie from 'js-cookie';
import { useTranslation } from 'react-i18next';

import { languages } from '../../i18next/languages';
import LazyImage from './../lazy-image/LazyImage';

const ImageSlider = ({ sliderData }) => {
  const { t } = useTranslation();
  const [direction, setDirection] = useState('left');

  useEffect(() => {
    const currentLanguageCode = cookie.get('i18next') || 'en';
    const currentLanguage = languages.find(
      (l) => l.code === currentLanguageCode
    );

    if (currentLanguage.dir === 'rtl') setDirection('right');
    else setDirection('left');
  }, [t]);

  const [current, setCurrent] = useState(0);
  const length = sliderData?.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(sliderData) || sliderData.length <= 0) {
    return null;
  }

  return (
    <section className="h-full border-b-2 pb-4 rounded-3xl flex justify-center items-center w-full lg:w-4/5">
      {sliderData.length > 1 &&
        (direction === 'left' ? (
          <BsFillArrowLeftCircleFill
            className="mr-1"
            onClick={prevSlide}
            size={40}
          />
        ) : (
          <BsFillArrowRightCircleFill
            className="ml-1"
            onClick={nextSlide}
            size={40}
          />
        ))}
      <div
        className="detail__image w-full rounded-3xl
"
      >
        <div className="max-w-lg mx-auto h-full">
          {sliderData
            .map((slide) => (
              <LazyImage
                key={slide}
                src={slide}
                alt="product"
                classname="w-full object-cover h-full rounded-3xl
                "
              />
            ))
            .filter((slide, index) => index === current)}
        </div>
      </div>
      {sliderData.length > 1 &&
        (direction === 'left' ? (
          <BsFillArrowRightCircleFill
            className="ml-1"
            onClick={prevSlide}
            size={40}
          />
        ) : (
          <BsFillArrowLeftCircleFill
            className="mr-1"
            onClick={nextSlide}
            size={40}
          />
        ))}
    </section>
  );
};

export default ImageSlider;
