import React from 'react';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation();
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_vjfd0qo',
        'template_0ydgqku',
        e.target,
        'hXTpsjHX3Lm2rpGoF'
      )
      .then(
        (result) => {
          toast.success('Email Sent Successfully', {
            position: 'top-left',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        },
        (error) => {
          toast.error('Something Went Wrong, Try Again', {
            position: 'top-left',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      );
    e.target.reset();
  }

  return (
    <div id="contact" className="flex flex-col items-center w-full mt-10 px-10">
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <h1 className="text-5xl text-amber-100 ">{t('contact_us')}</h1>
      <div className="w-full flex mt-10">
        <div className=" w-full ">
          <form onSubmit={sendEmail}>
            <input
              required
              name="name"
              type="text"
              className="feedback-input"
              placeholder={t('name')}
            />
            <input
              required
              name="email"
              type="email"
              className="feedback-input"
              placeholder={t('email')}
            />
            <textarea
              required
              name="message"
              className="feedback-input"
              placeholder={t('comment')}
            ></textarea>
            <input type="submit" value={t('submit')} />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
