import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Card from '../card/Card';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import cookie from 'js-cookie';

import { languages } from '../../i18next/languages';

const Products = () => {
  const products = useSelector((state) => state.product.products);
  const { t } = useTranslation();
  const [direction, setDirection] = useState('left');
  const arrow =
    direction === 'left' ? (
      <BsArrowRight size={50} />
    ) : (
      <BsArrowLeft size={50} />
    );

  useEffect(() => {
    const currentLanguageCode = cookie.get('i18next') || 'en';
    const currentLanguage = languages.find(
      (l) => l.code === currentLanguageCode
    );

    if (currentLanguage.dir === 'rtl') setDirection('right');
    else setDirection('left');
  }, [t]);

  function handleScroll() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  return (
    <div id="products" className="flex flex-col items-center w-full mt-20 px-4">
      <h1 className="text-5xl text-amber-100 ">{t('products')}</h1>
      <div className="sm:grid sm:gap-x-6 sm:gap-y-6 lg:grid-cols-2 xl:grid-cols-3 md:justify-center py-10 w-full">
        <Link
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          to={`/products/${products[3]?.id}`}
        >
          <Card title={products[3]?.title} img={products[3]?.images[0]} />
        </Link>

        <Link
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          to={`/products/${products[20]?.id}`}
        >
          <Card title={products[20]?.title} img={products[20]?.images[0]} />
        </Link>

        <Link
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          to={`/products/${products[27]?.id}`}
        >
          <Card title={products[27]?.title} img={products[27]?.images[0]} />
        </Link>

        <Link
          className="xl:hidden"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          to={`/products/${products[21]?.id}`}
        >
          <Card title={products[21]?.title} img={products[21]?.images[0]} />
        </Link>
      </div>
      <Link onClick={handleScroll} to="/products">
        <button
          className="text-amber-100 text-3xl p-4 ease-in-out hover:scale-110 hover:text-amber-50 flex items-center border-solid border border-slate-500 rounded-3xl active:translate-y-1 z-10 duration-200 "
          type="button"
        >
          {t('explore')}
          <span className="text-5xl ltr:ml-2 rtl:mr-2 inline-block mt-2">
            {arrow}
          </span>
        </button>
      </Link>
    </div>
  );
};

export default Products;
