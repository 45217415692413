import React from 'react';
import LazyImage from '../lazy-image/LazyImage';

const Card = ({ title, img }) => {
  return (
    <div className="mb-6 card__wrapper sm:mb-0 border-2 mx-auto max-w-sm sm:max-w-md md:max-w-lg hover:-translate-y-0.5 duration-100 group border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
      <div className="card__container">
        <LazyImage
          src={img}
          alt="product"
          classname="w-full h-full object-cover"
          placeholderClassname=" h-full w-full bg-grey border-2"
        />
      </div>
      <div className="p-2 w-full flex justify-center items-end bg-slate-700 group-hover:bg-slate-400 group-hover:text-zinc-900 font-mono text-white transition duration-300 ease-in">
        <h1 className="text-2xl capitalize tracking-wide text-center w-full font-semibold">
          {title}
        </h1>
      </div>
    </div>
  );
};

export default Card;
