import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/home/Home';
import ProductsPage from './pages/products/ProductsPage';
import Footer from './components/footer/Footer';
import Navbar from './components/navbar/Navbar';
import ProductDetailsPage from './pages/productDetailsPage/ProductDetailsPage';
import { useDispatch } from 'react-redux';
import { addProduct } from '../src/store/product/productSlice';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../src/firebase-config';
import ErrorPage from './pages/ErrorPage/ErrorPage';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    onSnapshot(collection(db, 'products'), (snapshot) => {
      dispatch(
        addProduct(
          snapshot.docs.map((product) => {
            return { ...product.data(), id: product.id };
          })
        )
      );
    });
  }, [dispatch]);

  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path={`/products/:productId`} element={<ProductDetailsPage />} />
        <Route path={`/404`} element={<ErrorPage text="Page Not Found" />} />
        <Route element={<ErrorPage text="Page Not Found" />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
