import React from 'react';
import logo from '../../assets/images/logo.png';
import { FaSnapchat, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import footerRLogo from '../../assets/images/logor.png';
import footerLavoLogo from '../../assets/images/lavo.png';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();

  function handleScroll() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  return (
    <footer>
      <div className={`p-10 bg-slate-700 text-zinc-200`}>
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 gap-x-40 md:gap-x-20 ">
            <div className="mb-5 flex flex-col justify-around items-center lg:items-start">
              <img
                className="logo mb-11 lg:self-start"
                src={logo}
                alt="checkmark"
              />
              <div className="flex flex-col justify-center items-center md:items-start ltr:md:text-left rtl:text-right">
                <p className="font-bold ltr:text-lg rtl:text-xl mb-2 md:self-center lg:self-start">
                  {t('contact_us')}
                </p>
                <p className="w-full">{t('email')} : info@rozzamakeup.com</p>
                <div className="flex flex-col items-end">
                  <p className='rtl:pl-2'>
                    {t('phone_number')} : {t('number')}
                  </p>
                  <p>{t('number_2')}</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-col h-full justify-around items-center mt-2 md:-mt-2  text-lg">
                <p className="text-3xl text-zinc-100 ltr:tracking-widest mb-2 md:mb-0 font-medium ">
                  {t('links')}
                </p>
                <Link
                  onClick={handleScroll}
                  to="/"
                  className="mx-5 hover:text-secondary transform hover:scale-105"
                >
                  {t('home')}
                </Link>
                <HashLink
                  onClick={handleScroll}
                  to="/#about"
                  smooth={true}
                  className="mx-5 hover:text-secondary transform hover:scale-105 scroll-smooth"
                >
                  {t('about')}
                </HashLink>
                <Link
                  onClick={handleScroll}
                  to="/products"
                  className="mx-5 hover:text-secondary transform hover:scale-105"
                >
                  {t('products')}
                </Link>
                <HashLink
                  onClick={handleScroll}
                  smooth={true}
                  to="/#contact"
                  className="mx-5 hover:text-secondary transform hover:scale-105"
                >
                  {t('contact_us')}
                </HashLink>
              </div>
            </div>
            <div className="mb-5 md:col-span-2 lg:col-span-1 flex flex-col">
              <div className="flex justify-around  mb-5 mt-2 lg:mb-6">
                <a
                  href={`https://www.snapchat.com/add/rozzamakeup?share_id=MzYzRjlE&locale=en_US`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaSnapchat size="3rem" />
                </a>
                <a
                  href={`https://www.instagram.com/rozzamake_up/?utm_medium=copy_link`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram size="3rem" />
                </a>
              </div>
              <div className="flex justify-around mt-10">
                <img className="footer__rlogo" src={footerRLogo} alt="R logo" />
                <img
                  className="footer__lavologo"
                  src={footerLavoLogo}
                  alt="Lavo logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
