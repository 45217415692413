import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPage = ({ text }) => {
  return (
    <div className="bg-zinc-900 w-full h-full p-4 overflow-x-hidden">
      <div className="h-screen w-screen flex justify-center items-center">
        <div className="text-center">
          <h1 className="font-serif text-4xl sm:text-5xl lg:text-6xl text-white">
            {text}
          </h1>
          <Link
            className="font-serif block mt-5 text-amber-100 text-3xl sm:text-4xl lg:text-5xl hover:scale-110 hover:text-amber-50"
            to="/"
          >
            Go Back
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
