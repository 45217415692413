import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ImageSlider from '../../components/image-slider/ImageSlider';
import ClipLoader from 'react-spinners/ClipLoader';
import cookie from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { BsArrowRight } from 'react-icons/bs';

const ProductDetailsPage = () => {
  let navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();
  const [langCode, setLangCode] = useState('');

  const product = useSelector((state) =>
    state.product.products.filter((product) => product.id === params.productId)
  );

  const productIndex = useSelector((state) =>
    state.product.products.findIndex(
      (product) => product.id === params.productId
    )
  );

  const products = useSelector((state) => state.product.products);

  const description = product[0]?.descriptions?.filter(
    (desc) => desc.code === langCode
  );

  useEffect(() => {
    if (product.length !== 0) {
      setLoading(false);
    }
  }, [product.length]);

  useEffect(() => {
    if (products.length !== 0) {
      const array = products.filter(
        (product) => product.id === params.productId
      );
      if (array.length === 0) {
        navigate('/404');
      }
    }
  }, [navigate, params.productId, products]);

  useEffect(() => {
    const currentLanguageCode = cookie.get('i18next') || 'en';
    setLangCode(currentLanguageCode);
  }, [t]);

  return (
    <div className="bg-zinc-900 w-full h-full p-4 overflow-x-hidden">
      {loading ? (
        <div className="h-screen flex items-center justify-center">
          <ClipLoader color="white" size={150} />
        </div>
      ) : (
        <div className="mb-10 h-full w-full mt-20 lg:mt-16 ">
          <div className="w-full flex justify-end text-amber-100 hover:text-amber-50">
            <button
              onClick={() => navigate('/products', { state: productIndex })}
              className="w-20 h-20 flex items-center justify-center text-xl  rounded-full hover:border-4  hover:border-slate-100 z-10  duration-200 ease-in-out cursor-pointer mb-4 lg:mb-2 lg:mt-3 md:mr-1 md:rtl:ml-1 rtl:rotate-180"
            >
              <BsArrowRight className="p-0 m-0" size={50} />
            </button>
          </div>
          <div className="text-white mb-10 h-full w-full lg:flex lg:justify-center lg:items-start ">
            <ImageSlider sliderData={product[0]?.images} />
            <div className="w-full lg:p-4">
              <h1 className="text-2xl mb-2 md:text-3xl text-white capitalize tracking-wide text-center w-full font-semibold p-3">
                {product[0]?.title}
              </h1>
              <div className="text-lg md:text-xl text-justify md:text-justify w-full text-white sm:white tracking-wide leading-8">
                <p>{description && description[0].value}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetailsPage;
